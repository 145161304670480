var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// @mui
import { alpha, styled } from '@mui/material/styles';
import { ListItemIcon, ListSubheader, ListItemButton } from '@mui/material';
// config
import { ICON, NAV } from '../../../config-global';
export var StyledItem = styled(ListItemButton, {
    shouldForwardProp: function (prop) { return prop !== 'active' && prop !== 'caption'; },
})(function (_a) {
    var active = _a.active, disabled = _a.disabled, depth = _a.depth, caption = _a.caption, theme = _a.theme;
    var isLight = theme.palette.mode === 'light';
    var subItem = depth !== 1;
    var activeStyle = __assign({ color: theme.palette.primary.main, backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity) }, (!isLight && {
        color: theme.palette.primary.light,
    }));
    var activeSubStyle = {
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
    };
    return __assign(__assign(__assign(__assign({ position: 'relative', textTransform: 'capitalize', paddingLeft: theme.spacing(2), paddingRight: theme.spacing(1.5), marginBottom: theme.spacing(0.5), color: theme.palette.text.secondary, borderRadius: theme.shape.borderRadius, height: NAV.H_DASHBOARD_ITEM }, (subItem && __assign(__assign({ height: NAV.H_DASHBOARD_ITEM_SUB }, (depth > 2 && {
        paddingLeft: theme.spacing(depth),
    })), (caption && {
        height: NAV.H_DASHBOARD_ITEM,
    })))), (active && __assign(__assign({}, activeStyle), { '&:hover': __assign({}, activeStyle) }))), (subItem &&
        active && __assign(__assign({}, activeSubStyle), { '&:hover': __assign({}, activeSubStyle) }))), (disabled && {
        '&.Mui-disabled': {
            opacity: 0.64,
        },
    }));
});
// ----------------------------------------------------------------------
export var StyledIcon = styled(ListItemIcon)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: ICON.NAV_ITEM,
    height: ICON.NAV_ITEM,
});
export var StyledDotIcon = styled('span', {
    shouldForwardProp: function (prop) { return prop !== 'active'; },
})(function (_a) {
    var active = _a.active, theme = _a.theme;
    return (__assign({ width: 4, height: 4, borderRadius: '50%', backgroundColor: theme.palette.text.disabled, transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shorter,
        }) }, (active && {
        transform: 'scale(2)',
        backgroundColor: theme.palette.primary.main,
    })));
});
// ----------------------------------------------------------------------
export var StyledSubheader = styled(ListSubheader)(function (_a) {
    var theme = _a.theme;
    return (__assign(__assign({}, theme.typography.overline), { fontSize: 11, paddingTop: theme.spacing(3), paddingBottom: theme.spacing(1), color: theme.palette.text.secondary }));
});
